.activity-form,
.new-activity-form {
  .radio-select-group {
    @apply flex w-fit mx-auto;

    .Polaris-RadioSelect {
      @apply w-32 !h-4 !rounded-none;

      &:first-child {
        @apply !rounded-l-md;
      }

      &:last-child {
        @apply !rounded-r-md;
      }
    }
  }
}

.new-activity-form {
  .Polaris-RadioSelect {
    @apply !w-16;
  }
}
