.Polaris-DescriptionList {
  .Polaris-DescriptionList__Term {
    @apply pl-4 truncate;
  }

  .Polaris-DescriptionList__Description {
    @apply px-4 truncate;
  }
}

.relative {
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    .ck.ck-editor__main {
      .ck-content.ck-editor__editable_inline {
        padding-bottom: 40px;
      }
    }
  }
}
