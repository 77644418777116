.simple-calendar {
  table {
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    background-color: rgba(0, 0, 0, 0);
    @apply border border-primary-50 border-collapse border-t-0;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
  }

  tr {
    border-collapse: collapse;
  }

  th {
    padding: 6px;
    /* border-collapse: collapse;
    border-bottom: 1px solid rgb(221, 221, 221);
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221); */
    border-top: 0px none rgb(51, 51, 51);
    box-sizing: border-box;
    text-align: left;

    @apply text-sm font-semibold leading-6 text-gray-700 border border-gray-100 border-collapse;
  }

  td {
    padding: 6px;
    vertical-align: top;
    width: 14%;

    > div {
      span {
        @apply overflow-hidden text-ellipsis;
      }
    }
    @apply border border-gray-100 bg-white;
  }

  .calendar-heading {
    @apply flex items-center justify-between mb-2;

    nav {
      @apply flex gap-3;

      a {
        @apply bg-gray-100 border px-2 py-1 rounded-xl;
      }
    }
  }

  .calendar-table {
    @apply h-[calc(100vh-10rem)] overflow-y-scroll;
  }

  .month-calendar {
    .day {
      @apply relative py-2 px-3;

      > div {
        span {
          @apply overflow-hidden text-ellipsis;
        }
      }
    }

    .today {
      > span {
        @apply bg-white flex w-6 h-6 items-center justify-center rounded-full bg-primary font-semibold text-white my-2 mx-3;
      }
    }
  }

  .week-calendar {
    .day {
      @apply h-16;
    }

    td {
      width: 13.4%;
      a {
        @apply whitespace-normal;
      }
    }
    .hour-label {
      @apply w-[6%] pr-2 text-right text-xs leading-5 text-gray-400;
    }

    th {
      @apply font-normal;
    }

    th.today {
      @apply flex items-center justify-center;
      span {
        @apply flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white ml-1;
      }
    }
  }

  .day-calendar {
    .day {
      @apply h-16;
    }

    .hour-label {
      @apply w-[1%] pr-2 text-right text-xs leading-5 text-gray-400;
    }
  }

  .year-calendar {
    .month {
      .table {
        @apply border-0;

        th {
          @apply border-0 text-center;
        }
      }

      .day {
        @apply bg-white py-1.5 hover:bg-gray-100 focus:z-10 text-black;
        time {
          @apply mx-auto flex h-7 w-7 items-center justify-center rounded-full;
        }
        &.prev-month,
        &.next-month {
          @apply bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10;
        }
      }

      .today {
        @apply bg-white hover:bg-gray-100 text-black py-1.5;
        time {
          @apply mx-auto flex h-7 w-7 items-center justify-center rounded-full bg-primary font-semibold text-white;
        }
      }

      .has-events {
        @apply cursor-pointer relative;
        &:before {
          content: '';
          @apply mx-0.5 h-1 w-1 rounded-full bg-primary absolute top-1 right-1;
        }
      }
    }
  }

  .wday-0 {
  }
  .wday-1 {
  }
  .wday-2 {
  }
  .wday-3 {
  }
  .wday-4 {
  }
  .wday-5 {
  }
  .wday-6 {
  }

  .past {
  }
  .future {
  }

  .start-date {
  }

  .prev-month {
    @apply bg-gray-50 text-gray-500;
  }
  .next-month {
    @apply bg-gray-50 text-gray-500;
  }
  .current-month {
    @apply bg-white;
  }

  .has-events {
  }
}

.cell-link {
  @apply flex-auto truncate font-medium text-gray-900 hover:text-primary;
}
