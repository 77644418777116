.ts-wrapper .ts-dropdown {
  @apply z-[9999];

  button {
    @apply px-3 py-2 text-xs;
  }
}

.ts-control {
  @apply border-gray-500 rounded-md;

  .item {
    @apply rounded-sm;
  }
}
