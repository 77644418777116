@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'tom-select/dist/css/tom-select.min.css';
@import 'flatpickr/dist/flatpickr';

/* Add any additional custom CSS files here */
@import 'custom/simple-calender.css';
@import 'custom/email-page-style';
@import 'custom/email-template';
@import 'custom/calendar-page';
@import 'custom/tom-select';
@import 'custom/flatpickr';
@import 'custom/activity';
@import 'custom/pagy';

@import 'actiontext';
@import 'highlight.js/styles/default.css';

.flatpickr-input {
  @apply border border-gray-300 rounded-md p-2 w-full focus:ring-indigo-500 focus:border-indigo-500 block shadow-sm sm:text-sm;
}

#error_explanation {
  color: var(--p-color-text-critical);
  h2 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  ul {
    color: var(--p-color-text-critical);
    margin: 0 0 30px 0;
  }
}

html.Polaris-Summer-Editions-2023 {
  /* background-color: var(--p-color-bg-app); */
  /* color: var(--p-color-text); */
  --p-color-bg-app: #fff !important;
  --p-color-text: rgb(35, 37, 41) !important;
  --p-color-text-secondary: rgb(117, 119, 124) !important;
  --p-color-bg-surface-secondary-active: #fbfbfb !important;
  --p-color-bg-surface-secondary-hover: rgb(238, 239, 241) !important;
  --p-color-bg-fill-inverse-hover: rgb(238, 239, 241) !important;
  --p-color-nav-bg-surface-selected: rgb(238, 239, 241);
  /* --p-color-bg-fill-inverse-active: rgb(238, 239, 241) !important; */
}

.Polaris-IndexTable__TableCell {
  @apply truncate max-w-64;
}

.Polaris-TopBar {
  background-color: #fff !important;
  box-shadow: none !important;
  @apply border-b border-primary-50;
}
.Polaris-Navigation {
  border-right: 1px solid rgb(238, 239, 241) !important;
}
.Polaris-Navigation__ItemWrapper {
  padding: 0px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.Polaris-Navigation__PrimaryNavigation {
  padding-top: 8px !important;
}
.Polaris-Navigation__Section {
  /* --p-space-400: 10px; */
  /* padding: var(--p-space-400) 0; */
}
.Polaris-Navigation__SectionHeading .Polaris-Text--root {
  font-weight: 500 !important;
  line-height: 16px !important;
  font-size: 12px !important;
}
.Polaris-TopBar-Menu__Activator {
  color: rgb(35, 37, 41) !important;
}
.Polaris-RadioSelect {
  @apply flex items-center justify-center overflow-hidden rounded-lg border border-primary-50 py-5 cursor-pointer;
}
.Polaris-RadioSelect.active {
  @apply border-indigo-200 bg-indigo-50 text-primary;
}

.flash-message {
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
  position: relative;
}

.flash-alert {
  background-color: #f8d7da;
  color: #721c24;
}
.flash-notice {
  background-color: #d1ecf1;
  color: #0c5460;
}
.flash-success {
  background-color: #d4edda;
  color: #155724;
}
.flash-warning {
  background-color: #fff3cd;
  color: #856404;
}

.flash-message .close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: inherit;
  cursor: pointer;
}

.sticky-table {
  th,
  td {
    &:first-child {
      @apply sticky left-0 border-r border-primary-50 min-w-72;
    }
    /* rest of the columns */
    &:not(:first-child) {
      @apply min-w-48;
    }
  }
  td {
    &:first-child {
      @apply bg-white;
    }
  }
}

.table-page {
  > .Polaris-Box {
    padding: 0.5rem 0.5rem !important;
  }
}

.simple-input {
  @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6;
}

.filter-line {
  @apply relative mr-8;

  /* > div:first-child .Polaris-Select__Backdrop {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  > div:not(:first-child) .Polaris-Select__Backdrop {
    border-radius: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }

  .filter-value .Polaris-TextField__Backdrop {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
 */

  .close-button {
    @apply !absolute top-0 left-full !ml-1 p-2 rounded-full z-50;
  }
}

.tab-pills {
  @apply flex items-center gap-1 py-2 px-4;
  .tab-pill {
    @apply cursor-pointer px-3 py-1 mx-0 rounded-md text-sm font-medium text-gray-500 hover:text-gray-700 hover:bg-primary-40;
    &.active {
      @apply bg-primary/[.08] text-gray-900;
    }
  }
  .Polaris-Button {
    @apply cursor-pointer px-3 py-1 mx-0 rounded-md text-sm font-medium text-gray-500 hover:text-gray-700 hover:bg-black/[.05] border-0 !shadow-none;
    &.active {
      @apply bg-primary-50 text-gray-900;
    }
  }
}

.Polaris-TextField__Backdrop {
  --p-border-radius-200: 0.25rem;
  @apply border-0 shadow-sm;
}

.Polaris-LegacyCard {
  @apply !shadow-none !border-primary-50;
  &::before {
    @apply !border-primary-50 !shadow-sm border !border-primary-50 !mix-blend-normal;
  }
}
.Polaris-DescriptionList {
  .Polaris-DescriptionList__Term {
    @apply pl-0;
    .Polaris-Text--headingSm {
      @apply text-gray-500 font-normal;
    }
  }
  .Polaris-DescriptionList__Description {
    @apply text-gray-700;
  }
}

.ck-editor__editable {
  min-height: 300px;
}

[x-cloak] {
  display: none;
}

.card-heading {
  @apply text-base font-semibold leading-6 text-gray-900;
}

.Polaris-DropZone__Container .Polaris-LegacyStack {
  @apply items-center;
}
#custom-confirm-dialog {
  @apply pointer-events-none;

  #confirmation-body {
    @apply transition ease-out duration-300 transform opacity-0 translate-y-14 sm:translate-y-4 sm:scale-95;
  }

  &.opened {
    @apply pointer-events-auto;

    #confirmation-body {
      @apply opacity-100 translate-y-0 sm:scale-100;
    }
  }
}

.Polaris-Avatar--shapeRound {
  @apply !rounded-full;
}

.divider {
  @apply mx-1 w-1 h-1 rounded-full bg-gray-600;
}

/* navigation override start */
html[class~='Polaris-Summer-Editions-2023'] {
  .Polaris-Navigation__ItemInnerWrapper:is(:hover, :focus-visible) {
    @apply bg-primary-40;
  }

  .NavigationList .Polaris-Navigation__Item.Polaris-Navigation__Item--selected {
    @apply bg-primary-50;
  }

  .Polaris-Navigation {
    @apply bg-gradient-to-b from-primary to-primary-400  text-white fill-white;

    .Polaris-Navigation__ListItem {
      @apply mb-0.5;
    }

    .Polaris-Navigation__ItemInnerWrapper:is(:hover, :focus-visible) {
      @apply bg-transparent;
    }
    .Polaris-Navigation__ItemInnerWrapper--selected {
      @apply bg-transparent;
    }
    .Polaris-Text--subdued {
      @apply text-gray-500;
    }
    .Polaris-Icon svg {
      @apply fill-gray-400;
    }
    .Polaris-Navigation__Item {
      &.Polaris-Navigation__Item--selected {
        @apply !bg-primary-300;
        .Polaris-Navigation__Text {
          @apply text-white;
        }
        svg {
          @apply fill-white;
        }
      }

      &:hover {
        @apply !bg-primary-300;
        /* background-color: #592e83; */

        .Polaris-Navigation__Text {
          @apply text-white;
        }
        svg {
          @apply fill-white;
        }
      }
    }

    .Polaris-Navigation__Text {
      @apply text-gray-300;
    }
    svg {
      @apply fill-gray-400;
    }
  }
  /* Navigation override end */

  .Polaris-Button.Polaris-Button--primary {
    @apply bg-gradient-to-b from-primary to-primary-300 border-primary/20 text-white rounded-md shadow-sm hover:bg-primary/90 hover:shadow-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;

    &::before {
      @apply hidden;
    }
  }
  .Polaris-Button.Polaris-Button--plain {
    &.Polaris-Button--iconOnly {
      svg {
        @apply fill-gray-500;
      }
    }
  }
  .Polaris-IndexTable__TableHeading {
    @apply bg-primary-40;
  }
}

.Polaris-Modal-Header {
  --pc-box-background: #f8f3ff !important;
}

.Polaris-Navigation__Section--withSeparator {
  --p-color-border-secondary: #9984d4;
}
.Polaris-InlineError {
  font-size: 12px !important;
}
