.card-overlay {
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  transition: opacity 0.3s ease;
}

.card-container:hover .card-overlay {
  opacity: 1;
}

.card-title {
  z-index: 3;
}
