.calendar-page-container {
  @apply flex;

  .calendar-section {
    @apply w-3/4;

    nav.calendar-nav {
      @apply flex items-center justify-center gap-3;

      a {
        @apply whitespace-nowrap border-b-2 border-transparent px-1 py-3 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700;
      }

      a.active {
        @apply whitespace-nowrap border-b-2 border-primary/75 px-1 py-3 text-sm font-medium text-primary;
      }
    }
  }

  .events-section {
    @apply w-1/4 px-4 mt-2;
  }
}
