.pagy-container {
  @apply flex items-center justify-between bg-white px-4 py-3 sm:px-6;
}

.pagy {
  @apply isolate inline-flex -space-x-px rounded-lg shadow-sm;

  a {
    @apply relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0;

    &:not([href]) {
      @apply text-gray-400 bg-gray-50 cursor-default;
    }

    &.current {
      @apply z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
    }

    &:first-child {
      @apply rounded-l-md;
    }

    &:last-child {
      @apply rounded-r-md;
    }
  }

  label {
    @apply inline-block whitespace-nowrap bg-gray-200 rounded-lg px-3 py-0.5;

    input {
      @apply bg-gray-100 border-none rounded-md;
    }
  }
}

.info {
  @apply text-sm text-gray-700 shadow-none;
}
